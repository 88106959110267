<template>
  <section
    v-show="isVisible"
    class="mb-xl mt-xl">
    <div class="game">
      <div class="game-header">
        <div class="game-content">
          <h3 class="text-size-h3 text-bold mb-xxs">
            &laquo;Журнал капитана Тундры&raquo;
          </h3>
          <div class="color-meta text-size-s">
            Читайте космическую новеллу, пока ждёте новых заданий олимпиады!
          </div>
        </div>
      </div>
      <div class="game__frame">
        <iframe
          ref="frame"
          :src="IFRAME_SRC"
          allowfullscreen
          frameborder="0"
          @load="onIframeLoad"></iframe>
      </div>
    </div>
  </section>
</template>

<script>
import { getToken } from "@/services/api";

export default {
  name: "NovelSection",
  props: {
    isVisible: {
      type: Object,
      required: true,
    },
  },
  watch: {
    isVisible: {
      immediate: true,
      handler(val) {
        this.postMessage({
          mute: !val,
        });
      },
    },
  },
  created() {
    this.IFRAME_SRC = `${process.env.VUE_APP_TALENT_BASE_URL}/novel/nto_connect`;
  },
  methods: {
    postMessage(data) {
      if (this.$refs.frame) {
        this.$refs.frame.contentWindow.postMessage(
          data,
          process.env.VUE_APP_TALENT_BASE_URL
        );
      }
    },
    onIframeLoad() {
      setTimeout(() => {
        this.postMessage({
          token: getToken(),
        });
      }, 4000);
    },
  },
};
</script>

<style lang="less" scoped>
.game {
  background-color: #fff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: @shadow-default;

  &-header {
    margin-bottom: 20px;
  }

  &.is-hidden &-header {
    margin-bottom: 0px;
  }

  &-btn {
    position: relative;
    width: 32px;
    height: 32px;
    padding: 0;
    border: 0;
    background-color: transparent;
    flex-grow: 0;
    flex-shrink: 0;
    margin-left: 10px;

    span {
      display: block;
      width: 50%;
      height: 50%;
      border: 2px solid @accent-blue;
      border-top: 0;
      border-left: 0;
      transform-origin: center;
      transform: translate(-50%, -50%) rotateZ(45deg);
      position: absolute;
      margin-top: -4px;
      top: 50%;
      left: 50%;
    }
  }

  &__frame {
    border-radius: 12px;
    overflow: hidden;
    position: relative;
    min-height: 700px;

    iframe {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
    }
  }

  @media screen and (max-width: 480px) {
    padding: 0;

    &__frame {
      min-height: 100vh;
      min-height: calc(~"100vh - 56px - 54px"); // верхняя и нижняя менюшки
    }

    &-header {
      padding: 20px;
    }
  }
}
</style>
